// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { type RouteRecordRaw } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { initNavAreaMyArea } from '@/router/guards/init-nav-area';

export const passwordRoutes: RouteRecordRaw = {
    path: '',
    children: [
        {
            path: 'password/forgot',
            name: Navigation.PasswordForgot,
            props: (route) => ({
                queryEmail: route.query.email,
            }),
            component: async () => import('../views/ForgotPasswordView.vue'),
            beforeEnter: [initNavAreaMyArea],
            meta: {
                titleKey: 'TITLES.FORGOT_PASSWORD',
            },
        },
        {
            path: 'password/forgot/success',
            name: Navigation.PasswordForgotSuccess,
            component: async () => import('../views/PasswordResetRequestSuccessView.vue'),
            beforeEnter: [initNavAreaMyArea],
            meta: {
                titleKey: 'TITLES.RESET_PASSWORD_REQUEST_SUCCESS',
            },
        },
        {
            path: 'password/reset',
            props: (route) => ({
                token: route.query.token,
                email: route.query.email,
            }),
            beforeEnter: [
                (to, from, next) => {
                    const token = to.query.token;
                    const email = to.query.email;
                    if (token == null || email == null) {
                        next(Navigation.NotFound);
                    } else {
                        next();
                    }
                },
                initNavAreaMyArea,
            ],
            name: Navigation.PasswordReset,
            component: async () => import('../views/PasswordResetView.vue'),
            meta: {
                titleKey: 'TITLES.RESET_PASSWORD',
            },
        },

        {
            path: 'password/reset/success',
            name: Navigation.PasswordResetSuccess,
            component: async () => import('../views/PasswordResetSuccessView.vue'),
            beforeEnter: [initNavAreaMyArea],
            meta: {
                titleKey: 'TITLES.RESET_PASSWORD_SUCCESS',
            },
        },
    ],
};
