export enum Environment {
    LOCAL = 'local',
    DEV = 'dev',
    STAGING = 'staging',
    PRODUCTION = 'production',
}

export function getEnvironment(): Environment {
    const currentLocation = window.location.hostname;
    if (currentLocation.includes('localhost')) {
        return Environment.LOCAL;
    }

    switch (currentLocation) {
        case 'dev-partnerportal.aws.containex.com':
        case 'portal.dev.containex.com':
            return Environment.DEV;
        case 'test-partnerportal.aws.containex.com':
        case 'portal.test.containex.com':
            return Environment.STAGING;
        default:
            return Environment.PRODUCTION;
    }
}
