// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { type LocationQuery, type RouteRecordRaw } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { initNavAreaByProvisionType } from '@/router/guards/init-nav-area';
import { initCart } from '@/router/guards/init-cart';
import { ensureProvisionType } from '@/router/guards/ensure-provision-type';
import { ACTIVE_FACETS_RENTAL, ACTIVE_FACETS_SALES } from '@containex/portal-business-logic';
import { ProductCategory } from '@/util/product-category';

function getAllowedProductFacets(query: LocationQuery, isRental: boolean): LocationQuery {
    const ALLOWED_FACETS = isRental ? ACTIVE_FACETS_RENTAL : ACTIVE_FACETS_SALES;

    const filteredQuery: LocationQuery = {};
    for (const key in query) {
        if (Object.prototype.hasOwnProperty.call(query, key) && ALLOWED_FACETS.find((facetKey) => facetKey === key)) {
            if (query[key] != null) {
                filteredQuery[key] = query[key];
            }
        }
    }

    return filteredQuery;
}

export const productRoutes: RouteRecordRaw = {
    path: '',
    children: [
        {
            path: 'sales/products',
            beforeEnter: [ensureProvisionType, initNavAreaByProvisionType, initCart],
            children: [
                {
                    path: '',
                    name: Navigation.SalesProductList,
                    props: (route) => ({
                        preSelectedFacets: [],
                        preFilteredFacetsQuery: getAllowedProductFacets(route.query, false),
                        headlineTranslationKey: 'NAVIGATION.MENU_ITEM.ALL_PRODUCTS',
                        productCategory: ProductCategory.SALES_ALL,
                    }),
                    component: async () => import('../product/sales/views/SalesProductListView.vue'),
                    meta: {
                        titleKey: 'TITLES.SALES_ALL_PRODUCTS',
                    },
                },
                {
                    path: 'new',
                    name: Navigation.SalesProductListNew,
                    props: (route) => ({
                        preSelectedFacets: ['isUsed:false'],
                        preFilteredFacetsQuery: getAllowedProductFacets(route.query, false),
                        headlineTranslationKey: 'NAVIGATION.MENU_ITEM.NEW_PRODUCTS',
                        productCategory: ProductCategory.SALES_NEW,
                    }),
                    component: async () => import('../product/sales/views/SalesProductListView.vue'),
                    meta: {
                        titleKey: 'TITLES.SALES_NEW_PRODUCTS',
                    },
                },
                {
                    path: 'used',
                    name: Navigation.SalesProductListUsed,
                    props: (route) => ({
                        preSelectedFacets: ['isUsed:true'],
                        preFilteredFacetsQuery: getAllowedProductFacets(route.query, false),
                        headlineTranslationKey: 'NAVIGATION.MENU_ITEM.USED_PRODUCTS',
                        productCategory: ProductCategory.SALES_USED,
                    }),
                    component: async () => import('../product/sales/views/SalesProductListView.vue'),
                    meta: {
                        titleKey: 'TITLES.SALES_USED_PRODUCTS',
                    },
                },
                {
                    path: 'details/:id/:variantId?',
                    name: Navigation.SalesProductDetail,
                    props: true,
                    component: async () => import('../product/sales/views/SalesProductDetailView.vue'),
                },
            ],
        },
        {
            path: 'rental/products',
            beforeEnter: [ensureProvisionType, initNavAreaByProvisionType, initCart],
            children: [
                {
                    path: '',
                    name: Navigation.RentalProductList,
                    props: (route) => ({
                        preSelectedFacets: [],
                        preFilteredFacetsQuery: getAllowedProductFacets(route.query, true),
                        headlineTranslationKey: 'NAVIGATION.MENU_ITEM.ALL_PRODUCTS',
                        productCategory: ProductCategory.RENTAL_ALL,
                    }),
                    component: async () => import('../product/rental/views/RentalProductListView.vue'),
                    meta: {
                        titleKey: 'TITLES.RENTAL_ALL_PRODUCTS',
                    },
                },
                {
                    path: 'sanitary',
                    name: Navigation.RentalProductListSanitary,
                    props: (route) => ({
                        preSelectedFacets: ['productCategory:SANITARY'],
                        preFilteredFacetsQuery: getAllowedProductFacets(route.query, true),
                        headlineTranslationKey: 'NAVIGATION.MENU_ITEM.SANITARY_CONTAINERS',
                        productCategory: ProductCategory.RENTAL_SANITARY,
                    }),
                    component: async () => import('../product/rental/views/RentalProductListView.vue'),
                    meta: {
                        titleKey: 'TITLES.RENTAL_SANITARY_PRODUCTS',
                    },
                },
                {
                    path: 'storage',
                    name: Navigation.RentalProductListStorage,
                    props: (route) => ({
                        preSelectedFacets: ['productCategory:STORAGE'],
                        preFilteredFacetsQuery: getAllowedProductFacets(route.query, true),
                        headlineTranslationKey: 'NAVIGATION.MENU_ITEM.STORAGE_CONTAINERS',
                        productCategory: ProductCategory.RENTAL_STORAGE,
                    }),
                    component: async () => import('../product/rental/views/RentalProductListView.vue'),
                    meta: {
                        titleKey: 'TITLES.RENTAL_STORAGE_PRODUCTS',
                    },
                },
                {
                    path: 'office',
                    name: Navigation.RentalProductListOffice,
                    props: (route) => ({
                        preSelectedFacets: ['productCategory:OFFICE'],
                        preFilteredFacetsQuery: getAllowedProductFacets(route.query, true),
                        headlineTranslationKey: 'NAVIGATION.MENU_ITEM.OFFICE_CONTAINERS',
                        productCategory: ProductCategory.RENTAL_OFFICE,
                    }),
                    component: async () => import('../product/rental/views/RentalProductListView.vue'),
                    meta: {
                        titleKey: 'TITLES.RENTAL_OFFICE_PRODUCTS',
                    },
                },
                {
                    path: 'details/:id/:variantId?',
                    name: Navigation.RentalProductDetail,
                    props: true,
                    component: async () => import('../product/rental/views/RentalProductDetailView.vue'),
                },
            ],
        },
    ],
};
