import { defineStore } from 'pinia';
import { useCookies } from '@vueuse/integrations/useCookies';
import type { CustomerDto, CompanyMinimalDto } from '@containex/portal-backend-dto';
import { computed, ref } from 'vue';

const CUSTOMER_NAME_COOKIE_KEY = 'customer_name';
const CUSTOMER_ID_COOKIE_KEY = 'customer_id';

export const useAuthenticationStore = defineStore('authentication', () => {
    const currentCustomer = ref<CustomerDto | undefined>(undefined);
    const completionToken = ref<string | undefined>(undefined);
    const authEmail = ref<string | undefined>(undefined);
    const assignedCompanies = ref<CompanyMinimalDto[] | undefined>(undefined);
    const impersonatingCustomer = ref<boolean>(false);
    const impersonationAttempt = ref<boolean>(false);
    const cookies = useCookies([CUSTOMER_ID_COOKIE_KEY, CUSTOMER_NAME_COOKIE_KEY]);

    return {
        currentCustomer,
        username: computed(() => currentCustomer.value?.first_name),
        userMail: computed(() => currentCustomer.value?.email),
        completionToken,
        authEmail,
        assignedCompanies,
        impersonatingCustomer,
        impersonationAttempt,
        setCurrentCustomer(customer: CustomerDto): void {
            cookies.set(CUSTOMER_ID_COOKIE_KEY, customer.id);
            cookies.set(CUSTOMER_NAME_COOKIE_KEY, customer.first_name);
            currentCustomer.value = customer;
            completionToken.value = undefined;
            authEmail.value = undefined;
        },
        removeCurrentCustomer(): void {
            cookies.remove(CUSTOMER_ID_COOKIE_KEY);
            cookies.remove(CUSTOMER_NAME_COOKIE_KEY);
            impersonatingCustomer.value = false;
            currentCustomer.value = undefined;
            completionToken.value = undefined;
            authEmail.value = undefined;
            assignedCompanies.value = undefined;
        },
        setImpersonatingCustomer(isImpersonatingCustomer: boolean): void {
            impersonatingCustomer.value = isImpersonatingCustomer;
        },
        setImpersonationAttempt(isImpersonationAttempt: boolean): void {
            impersonationAttempt.value = isImpersonationAttempt;
        },
        setAuthCompletionToken(authCompletionToken: string | null | undefined): void {
            completionToken.value = authCompletionToken ?? undefined;
        },
        setAuthEmail(email: string | undefined): void {
            authEmail.value = email;
        },
        setAssignedCompanies(companies: CompanyMinimalDto[]): void {
            assignedCompanies.value = companies;
        },
    };
});
