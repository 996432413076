// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { type RouteRecordRaw } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { initNavAreaByProvisionType } from '@/router/guards/init-nav-area';
import { initCart } from '@/router/guards/init-cart';
import { ensureProvisionType } from '@/router/guards/ensure-provision-type';
import { useAuthenticationQuery } from '@/composables/authentication';

export const cartRoutes: RouteRecordRaw = {
    path: '',
    children: [
        {
            path: 'sales/cart',
            name: Navigation.SalesCart,
            component: async () => import('../cart/views/SalesCartView.vue'),
            beforeEnter: [ensureProvisionType, initNavAreaByProvisionType, initCart],
            meta: {
                titleKey: 'TITLES.CART',
            },
        },
        {
            path: 'rental/cart',
            name: Navigation.RentalCart,
            component: async () => import('../cart/views/RentalCartView.vue'),
            beforeEnter: [ensureProvisionType, initNavAreaByProvisionType, initCart],
            meta: {
                titleKey: 'TITLES.CART',
            },
        },
        {
            path: 'sales/cart/checkout/success',
            name: Navigation.SalesCheckoutSuccess,
            component: async () => import('../checkout/views/CheckoutSuccessView.vue'),
            beforeEnter: [
                (to, from, next) => {
                    const { isUserLoggedIn } = useAuthenticationQuery();

                    if (isUserLoggedIn.value) {
                        next();
                    } else {
                        next(Navigation.Login);
                    }
                },
                ensureProvisionType,
                initNavAreaByProvisionType,
            ],
            meta: {
                titleKey: 'TITLES.SALES_CHECKOUT_SUCCESS',
            },
        },
        {
            path: 'rental/cart/checkout/success',
            name: Navigation.RentalCheckoutSuccess,
            component: async () => import('../checkout/views/CheckoutSuccessView.vue'),
            beforeEnter: [
                (to, from, next) => {
                    const { isUserLoggedIn } = useAuthenticationQuery();

                    if (isUserLoggedIn.value) {
                        next();
                    } else {
                        next(Navigation.Login);
                    }
                },
                ensureProvisionType,
                initNavAreaByProvisionType,
            ],
            meta: {
                titleKey: 'TITLES.RENTAL_CHECKOUT_SUCCESS',
            },
        },
    ],
};
