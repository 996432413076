import { Environment, getEnvironment } from '@/util/environment.ts';
import { BASIC_PAGE_TITLE } from '@/constants.ts';
import { useTitle } from '@vueuse/core';
import { watch } from 'vue';
import { i18n } from '@/i18n/i18n.ts';

export function prefixPageTitleWithEnv(mainPart?: string): string {
    const titleParts: string[] = [];
    const e: string = getEnvironment();

    if (e !== Environment.PRODUCTION) {
        titleParts.push(e.toUpperCase());
    }

    if (mainPart !== undefined) {
        titleParts.push(mainPart);
    }

    return titleParts.join(' - ');
}

export function assemblePageTitle(mainPart?: string): string {
    const titleParts: string[] = [];

    titleParts.push(prefixPageTitleWithEnv(mainPart));

    titleParts.push(BASIC_PAGE_TITLE);

    return titleParts.join(' - ');
}

export function setDynamicTitle(title: string): void {
    useTitle().value = assemblePageTitle(title);

    // when localization changes, previous page's title gets set again as a title automatically,
    // so we need to set the proper one again
    watch(i18n.global.locale, () => {
        useTitle().value = assemblePageTitle(title);
    });
}
