import { componentStyle } from '@/styling/util/component-style.ts';

// Copied radio button relevant design tokens form primevue material
// https://github.com/primefaces/primevue/blob/master/packages/themes/src/presets/material/base/index.js
// https://github.com/primefaces/primevue/blob/master/packages/themes/src/presets/material/radiobutton/index.js

const formField = {
    borderRadius: '{border.radius.sm}',
    transitionDuration: '{transition.duration}',
    background: '{surface.0}',
    disabledBackground: '{surface.100}',
    filledBackground: '{surface.100}',
    borderColor: '{surface.400}',
    hoverBorderColor: '{surface.900}',
    focusBorderColor: '{primary.color}',
    invalidBorderColor: '{red.800}',
    color: '{surface.900}',
    disabledColor: '{surface.600}',
    shadow: 'none',
};

export const radioButtonStyle = componentStyle({
    width: '20px',
    height: '20px',
    background: formField.background,
    checkedBackground: '{primary.contrast.color}',
    checkedHoverBackground: '{primary.contrast.color}',
    disabledBackground: formField.disabledBackground,
    filledBackground: formField.filledBackground,
    borderColor: formField.borderColor,
    hoverBorderColor: formField.hoverBorderColor,
    focusBorderColor: formField.focusBorderColor,
    checkedBorderColor: '{primary.color}',
    checkedHoverBorderColor: '{primary.color}',
    checkedFocusBorderColor: '{primary.color}',
    checkedDisabledBorderColor: formField.borderColor,
    invalidBorderColor: formField.invalidBorderColor,
    shadow: formField.shadow,
    focusRingWidth: '0',
    focusRingStyle: 'none',
    focusRingColor: 'unset',
    focusRingOffset: '0',
    focusRingShadow: 'none',
    transitionDuration: formField.transitionDuration,
    smWidth: '16px',
    smHeight: '16px',
    lgWidth: '24px',
    lgHeight: '24px',
    iconSize: '10px',
    iconCheckedColor: '{primary.color}',
    iconCheckedHoverColor: '{primary.color}',
    iconDisabledColor: formField.disabledColor,
    iconSmSize: '8px',
    iconLgSize: '12px',
});
