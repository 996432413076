const REFERRER_SESSION_STORAGE_KEY = 'referrer';

export function getReferrer(): string | undefined {
    return sessionStorage.getItem(REFERRER_SESSION_STORAGE_KEY) ?? undefined;
}

export function setGclidReferrer(gclid: string): void {
    sessionStorage.setItem(REFERRER_SESSION_STORAGE_KEY, `source=google, medium=cpc, gclid=${gclid}`);
}

export function setUtmReferrer(
    utmCampaign: string | undefined,
    utmMedium: string | undefined,
    utmSource: string | undefined
): void {
    sessionStorage.setItem(
        'referrer',
        `utm_campaign=${utmCampaign ?? ''}&utm_medium=${utmMedium ?? ''}&utm_source=${utmSource ?? ''}`
    );
}
