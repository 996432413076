// see https://github.com/orgs/vuejs/discussions/11798
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { type RouteRecordRaw } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { requireLoggedIn } from '@/router/guards/require-logged-in';
import { initNavAreaMyArea } from '@/router/guards/init-nav-area';
import { requireRetailer } from '@/router/guards/require-retailer';

export const accountRoutes: RouteRecordRaw = {
    path: 'account',
    beforeEnter: [requireLoggedIn, initNavAreaMyArea],
    children: [
        {
            path: '',
            name: Navigation.Account,
            component: async () => import('../account/views/AccountOverviewView.vue'),
            meta: {
                titleKey: 'TITLES.ACCOUNT',
            },
        },
        {
            path: 'settings',
            name: Navigation.AccountSettings,
            component: async () => import('../account/views/AccountSettingsView.vue'),
            meta: {
                titleKey: 'TITLES.ACCOUNT_SETTINGS',
            },
        },
        {
            path: 'users',
            name: Navigation.Users,
            component: async () => import('../account/views/UserListView.vue'),
            meta: {
                titleKey: 'TITLES.ACCOUNT_USERS',
            },
        },
        {
            path: 'employees',
            name: Navigation.Employees,
            component: async () => import('../account/views/EmployeesView.vue'),
            meta: {
                titleKey: 'TITLES.ACCOUNT_EMPLOYEES',
            },
        },
        {
            path: 'orders',
            name: Navigation.OrderList,
            component: async () => import('../account/views/OrderListView.vue'),
            meta: {
                titleKey: 'TITLES.ACCOUNT_ORDERS',
            },
        },
        {
            path: 'orders/:id',
            name: Navigation.OrderDetail,
            props: true,
            component: async () => import('../account/views/OrderDetailView.vue'),
        },
        {
            path: 'order-confirmations',
            name: Navigation.OrderConfirmationList,
            component: async () => import('../account/views/OrderConfirmationsListView.vue'),
            meta: {
                titleKey: 'TITLES.ACCOUNT_ORDER_CONFIRMATIONS',
            },
        },
        {
            path: 'order-confirmations/:id',
            name: Navigation.OrderConfirmationDetail,
            props: true,
            component: async () => import('../account/views/OrderConfirmationDetailView.vue'),
        },
        {
            path: 'offers',
            name: Navigation.OfferList,
            component: async () => import('../account/views/OfferListView.vue'),
            meta: {
                titleKey: 'TITLES.ACCOUNT_OFFERS',
            },
        },
        {
            path: 'offers/:id',
            name: Navigation.OfferDetail,
            props: true,
            component: async () => import('../account/views/OfferDetailView.vue'),
        },
        {
            path: 'invoices',
            name: Navigation.InvoiceList,
            component: async () => import('../account/views/InvoiceListView.vue'),
            meta: {
                titleKey: 'TITLES.ACCOUNT_INVOICES',
            },
        },
        {
            path: 'leads',
            name: Navigation.Leads,
            component: async () => import('../account/views/LeadsView.vue'),
            beforeEnter: [requireRetailer],
            meta: {
                titleKey: 'TITLES.ACCOUNT_LEADS',
            },
        },
        {
            path: 'leads/:id',
            props: (route) => ({
                id: route.params.id,
            }),
            name: Navigation.LeadDetails,
            component: async () => import('../account/views/LeadDetailView.vue'),
            beforeEnter: [requireRetailer],
        },
        {
            path: 'downloads',
            name: Navigation.Downloads,
            component: async () => import('../account/views/DownloadsView.vue'),
            meta: {
                titleKey: 'TITLES.ACCOUNT_DOWNLOADS',
            },
        },
    ],
};
