import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import { createApp } from 'vue';
import App from './App.vue';
import { i18n, initI18n } from './i18n/i18n';
import router from './router';
import Tooltip from 'primevue/tooltip';
import './styling/main_impl.scss';
import BadgeDirective from 'primevue/badgedirective';
import { handleGlobalVueError } from '@/logger/global-error-handler-vue';
import './logger/logger';
import { primeTheme } from '@/styling/theme.ts';
import { createHead } from '@unhead/vue/client';

await initI18n();

const app = createApp(App);
const head = createHead();

app.use(head);
app.use(router);
app.use(PrimeVue, primeTheme);
app.use(ToastService);
app.use(ConfirmationService);
app.directive('badge', BadgeDirective);
app.use(i18n);
app.use(createPinia());
app.directive('tooltip', Tooltip);

app.config.errorHandler = handleGlobalVueError;

app.mount('#app');

app.provide('ctxApp', app);
