import { type NavigationGuard } from 'vue-router';
import { setGclidReferrer, setUtmReferrer } from '@/util/referrer';

// eslint-disable-next-line func-style
export const initReferrer: NavigationGuard = (to) => {
    const query = to.query;
    if (query.gclid != null && typeof query.gclid === 'string') {
        setGclidReferrer(query.gclid);
    } else if (query.utm_campaign != null || query.utm_source != null || query.utm_medium != null) {
        setUtmReferrer(query.utm_campaign?.toString(), query.utm_medium?.toString(), query.utm_source?.toString());
    }
};
