import { type NavigationGuard } from 'vue-router';
import { Navigation } from '@/router/navigation';
import { stringValueOfParam } from '@/util/param';
import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication.ts';

// eslint-disable-next-line func-style
export const requireLoggedInWhitelist: NavigationGuard = async (to) => {
    const authenticationAction = useAuthenticationAction();
    const authenticationQuery = useAuthenticationQuery();

    await authenticationAction.fetchCurrentCustomerIfMissing.perform();

    const impersonationEnabled = true;
    const impersonationEmailWhitelist: string[] = ['containex.com', 'cloudflight.io', 'walter-group.com'];
    const emailInImpersonationWhitelist = impersonationEmailWhitelist.some(
        (email) =>
            authenticationQuery?.currentCustomer?.value?.email.toLowerCase().endsWith(email.toLowerCase()) ?? false
    );

    return authenticationQuery.isUserLoggedIn.value && impersonationEnabled && emailInImpersonationWhitelist
        ? true
        : {
              name: Navigation.Landing,
              params: {
                  market: stringValueOfParam(to.params.market).toLowerCase(),
                  language: stringValueOfParam(to.params.language).toLowerCase(),
              },
          };
};
