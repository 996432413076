import { computed, type ComputedRef } from 'vue';
import { useMarketStore } from '@/stores/market';
import { type MarketDto, ProvisionType } from '@containex/portal-backend-dto';
import { marketsApi, regionApi, zipCodeApi } from '@containex/portal-backend-api-client';
import { useMemoize } from '@vueuse/core';
import { httpClient } from '@/common/api/http-client';
import { useProvisionTypeQuery } from './provision-type';
import { ProvisionTypeNotGivenError } from '@/model/provision-type-not-given';

export interface MarketQuery {
    market: ComputedRef<MarketDto | null>;
    availableMarkets: ComputedRef<MarketDto[]>;
    currentSalesChannelId: ComputedRef<string | undefined>;
}

export interface MarketAction {
    initMarketData(): Promise<string>;

    setMarket(marketCode: string): void;

    getRegionByZipCode(zipCode: string): Promise<string | null>;

    zipCodeExistsInCurrentMarket(zipCode: string): Promise<boolean>;

    zipCodeExistsInCountryCode(zipCode: string, countryCode: string): Promise<boolean>;
}

export function useMarketQuery(): MarketQuery {
    const store = useMarketStore();
    const { currentProvisionType } = useProvisionTypeQuery();

    return {
        market: computed(() => store.market),
        availableMarkets: computed(() => store.availableMarkets),
        currentSalesChannelId: computed(() => {
            if (currentProvisionType.value === ProvisionType.Rental) {
                return store.market?.rentalChannelId ?? undefined;
            } else if (currentProvisionType.value === ProvisionType.Sales) {
                return store.market?.salesChannelId ?? undefined;
            }

            return undefined;
        }),
    };
}

export function useMarketAction(): MarketAction {
    const store = useMarketStore();
    const { currentProvisionType } = useProvisionTypeQuery();

    const zipCodeExistsApiCall = useMemoize(zipCodeApi.zipCodeExists.bind(zipCodeApi));

    return {
        setMarket(marketCode: string): void {
            store.setMarket(marketCode);
        },
        async getRegionByZipCode(zipCode: string): Promise<string | null> {
            if (currentProvisionType.value == null) {
                throw new ProvisionTypeNotGivenError();
            }

            const { data } = await regionApi.resolveRegion(httpClient, {
                marketCode: store.market?.code ?? '',
                zipCode,
                provisionType: currentProvisionType.value,
            });

            return data.region ?? null;
        },
        async zipCodeExistsInCurrentMarket(zipCode: string): Promise<boolean> {
            if (store.market == null) {
                return false;
            }

            const { data } = await zipCodeExistsApiCall(httpClient, {
                countryCode: store.market.code,
                zipCode,
            });

            return data.exists;
        },
        async zipCodeExistsInCountryCode(zipCode: string, countryCode: string): Promise<boolean> {
            const { data } = await zipCodeExistsApiCall(httpClient, {
                countryCode,
                zipCode,
            });

            return data.exists;
        },
        async initMarketData() {
            const { data } = await marketsApi.fetchMarkets(httpClient);

            store.availableMarkets = data.availableMarkets;
            store.setIdentifiedZipCode(data.zipCode ?? store.identifiedZipCode);
            return data.identifiedMarket.code;
        },
    };
}
