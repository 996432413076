import { createI18n } from 'vue-i18n';
import { zodTranslationsFor } from '@/i18n/zod-translations';
import { nextTick } from 'vue';
import { z } from 'zod';
import { makeZodI18nMap } from '@/i18n/zod-error-map';
import axios from 'axios';

const locale = 'de';
const fallbackLocale = 'en';

interface TranslationsResponse {
    [key: string]: string | TranslationsResponse;
}

export const i18n = createI18n({
    locale,
    fallbackLocale,
    messages: {},
    legacy: false,
});

export async function initI18n(): Promise<void> {
    await Promise.all([loadLocaleMessages(locale), loadLocaleMessages(fallbackLocale)]);
}

export function setI18nLanguage(localeToSet: string): void {
    // set the value inside and not to replace the proxy itself
    i18n.global.locale.value = localeToSet;

    document.querySelector('html')?.setAttribute('lang', localeToSet);
}

async function loadLocaleMessages(localeToLoad: string): Promise<void> {
    const { data } = await axios.get<TranslationsResponse>(`/assets/translations/${localeToLoad}.json`, {
        headers: {
            'Cache-Control': 'no-cache, no-store',
            Expires: '0',
        },
    });
    const zod = await zodTranslationsFor(localeToLoad);

    if (data != null) {
        i18n.global.setLocaleMessage(localeToLoad, {
            zod,
            ...data,
        });

        // visit https://zod.dev/ERROR_HANDLING?id=error-map-priority
        // to see how to overwrite errors individually
        z.setErrorMap(
            makeZodI18nMap({
                t: i18n.global.t,
            })
        );
    }

    return nextTick();
}
