import { computed, type ComputedRef } from 'vue';

import { type Cart, type LineItemGroup } from '@containex/portal-backend-api-client';
import { useCheckoutCartStore } from '../stores/checkout-cart-store';
import { useCartAction } from '@/composables/cart';
import {
    hasSameShippingAddress,
    hasSameLineItemsAndQuantity,
    hasSameLineItemGroups,
} from '../util/checkout-validation-helpers';

export interface CheckoutCartQuery {
    checkoutCart: ComputedRef<Cart | null>;
}

export interface CheckoutCartAction {
    isCheckoutCartConsistentWithDatabaseCart(): Promise<boolean>;
    initCheckoutCart(newCheckoutCart: Cart): void;
    resetCheckoutCart(): void;
    updateCheckoutCartShippingAddress(newShippingAddress: Cart['shipping_address']): void;
    updateCheckoutCartSessionPostalCode(updatedPostalCode: Cart['sessionPostalCode']): void;
    updateCheckoutCartLineItemGroup(newLineItemGroup: LineItemGroup): void;
    updateCheckoutCartLineItems(updatedLineItems: Cart['items']): void;
    updateCheckoutCartOrderInfo(updatedCart: Cart): void;
    updateCheckoutCartLineItemGroups(updatedLineItemGroups: LineItemGroup[]): void;
}

export function useCheckoutCartQuery(): CheckoutCartQuery {
    const store = useCheckoutCartStore();

    return {
        checkoutCart: computed(() => store.checkoutCart),
    };
}

export function useCheckoutCartAction(): CheckoutCartAction {
    const store = useCheckoutCartStore();
    const cartAction = useCartAction();

    return {
        async isCheckoutCartConsistentWithDatabaseCart(): Promise<boolean> {
            const checkoutCart = store.checkoutCart;

            if (checkoutCart == null) {
                throw Error('There is no checkout cart in the store during checkout process validation.');
            }

            const fetchedCart = await cartAction.retrievePureDatabaseCart(checkoutCart.id);

            if (fetchedCart == null) {
                throw Error(`Cart with id ${checkoutCart.id} could not be fetched during checkout process validation`);
            }

            if (fetchedCart.completed_at != null) {
                return false;
            }

            const validShippingAddress = hasSameShippingAddress(
                checkoutCart.shipping_address,
                fetchedCart.shipping_address
            );
            const validLineItems = hasSameLineItemsAndQuantity(checkoutCart.items, fetchedCart.items);
            const validLineItemGroups = hasSameLineItemGroups(checkoutCart.lineItemGroups, fetchedCart.lineItemGroups);
            const validOrderInformation =
                checkoutCart.sessionPostalCode === fetchedCart.sessionPostalCode &&
                checkoutCart.rentalStart === fetchedCart.rentalStart &&
                checkoutCart.rentalEnd === fetchedCart.rentalEnd;

            return validShippingAddress && validLineItems && validLineItemGroups && validOrderInformation;
        },
        initCheckoutCart(newCheckoutCart: Cart): void {
            // only set it once during initialization, we MUST NOT set it at a later time during checkout process
            if (store.checkoutCart == null) {
                store.setCheckoutCart(newCheckoutCart);
            }
        },
        resetCheckoutCart(): void {
            store.resetCheckoutCart();
        },
        updateCheckoutCartShippingAddress(updatedShippingAddress: Cart['shipping_address']): void {
            if (store.checkoutCart != null) {
                store.setCheckoutCart({ ...store.checkoutCart, shipping_address: updatedShippingAddress });
            }
        },
        updateCheckoutCartSessionPostalCode(updatedPostalCode: Cart['sessionPostalCode']): void {
            if (store.checkoutCart != null) {
                store.setCheckoutCart({ ...store.checkoutCart, sessionPostalCode: updatedPostalCode });
            }
        },
        updateCheckoutCartLineItemGroup(updatedLineItemGroup: LineItemGroup): void {
            if (store.checkoutCart != null) {
                const filteredGroups = store.checkoutCart.lineItemGroups.filter(
                    (group) => group.id !== updatedLineItemGroup.id
                );
                const updatedLineItemGroups = [...filteredGroups, updatedLineItemGroup];
                store.setCheckoutCart({ ...store.checkoutCart, lineItemGroups: updatedLineItemGroups });
            }
        },
        updateCheckoutCartLineItemGroups(updatedLineItemGroups: LineItemGroup[]): void {
            if (store.checkoutCart != null) {
                store.setCheckoutCart({ ...store.checkoutCart, lineItemGroups: updatedLineItemGroups });
            }
        },
        updateCheckoutCartLineItems(updatedLineItems: Cart['items']): void {
            if (store.checkoutCart != null) {
                store.setCheckoutCart({ ...store.checkoutCart, items: updatedLineItems });
            }
        },
        updateCheckoutCartOrderInfo(updatedCart: Cart): void {
            if (store.checkoutCart != null) {
                store.setCheckoutCart({
                    ...store.checkoutCart,
                    sessionPostalCode: updatedCart.sessionPostalCode,
                    shipping_address: updatedCart.shipping_address,
                    rentalStart: updatedCart.rentalStart,
                    rentalEnd: updatedCart.rentalEnd,
                });
            }
        },
    };
}
