import { computed, type ComputedRef } from 'vue';
import { useNavigationItemsStore } from '@/stores/navigation-items';
import { useI18n } from 'vue-i18n';
import { useMarketQuery } from '@/composables/market';
import { ProvisionType } from '@containex/portal-backend-dto';
import { Navigation } from '@/router/navigation';
import { NavArea } from '@/types/NavArea';
import type { MenuItem } from 'primevue/menuitem';
import { marketContainsProvision } from '@/util/marketContainsProvision';
import { useAuthenticationQuery } from './authentication';
import { useCompanyQuery } from './company';
import { useLeadQuery } from '@/account/composables/leads';

export interface NavigationItemsQuery {
    activeArea: ComputedRef<NavArea>;
    navAreas: ComputedRef<Record<NavArea, TypedMenuItem>>;
}

interface TypedMenuItem extends MenuItem {
    plainName: string;
    navBarItems: {
        name: ProvisionType;
        label: string;
        route: Navigation;
        count?: number;
        items?: MenuItem[];
    }[];
}

export interface NavigationItemsAction {
    setActiveArea(newValue: NavArea): void;
}

export function useNavigationItemsQuery(): NavigationItemsQuery {
    const store = useNavigationItemsStore();
    const { isUserLoggedIn } = useAuthenticationQuery();
    const { isRetailer } = useCompanyQuery();

    const { t } = useI18n();
    const { market } = useMarketQuery();

    return {
        activeArea: computed(() => store.activeArea),

        navAreas: computed(() => {
            const { amountNewLeads } = useLeadQuery();

            const navMenuList: Record<number, TypedMenuItem> = {};

            if (market.value == null) {
                return navMenuList;
            }

            if (marketContainsProvision(market.value, ProvisionType.Sales)) {
                navMenuList[NavArea.BUY] = {
                    name: t('NAVIGATION.AREAS.BUY'),
                    plainName: 'sales',
                    icon: 'pi pi-shopping-cart',
                    description: t('NAVIGATION.AREAS.BUY_DESCRIPTION'),
                    navArea: NavArea.BUY,
                    navBarItems: [
                        {
                            name: ProvisionType.Sales,
                            label: t('NAVIGATION.MENU_ITEM.PRODUCTS'),
                            route: Navigation.SalesProductList,
                            items: [
                                {
                                    name: ProvisionType.Sales,
                                    label: t('NAVIGATION.MENU_ITEM.ALL_PRODUCTS'),
                                    route: Navigation.SalesProductList,
                                },
                                {
                                    name: ProvisionType.Sales,
                                    label: t('NAVIGATION.MENU_ITEM.NEW_PRODUCTS'),
                                    route: Navigation.SalesProductListNew,
                                },
                                {
                                    name: ProvisionType.Sales,
                                    label: t('NAVIGATION.MENU_ITEM.USED_PRODUCTS'),
                                    route: Navigation.SalesProductListUsed,
                                },
                            ],
                        },
                    ],
                };
            }

            if (marketContainsProvision(market.value, ProvisionType.Rental)) {
                navMenuList[NavArea.RENT] = {
                    name: t('NAVIGATION.AREAS.RENT'),
                    plainName: 'rental',
                    icon: 'pi pi-calendar',
                    description: t('NAVIGATION.AREAS.RENT_DESCRIPTION'),
                    navArea: NavArea.RENT,
                    navBarItems: [
                        {
                            name: ProvisionType.Rental,
                            label: t('NAVIGATION.MENU_ITEM.ALL_PRODUCTS'),
                            route: Navigation.RentalProductList,
                            items: [
                                {
                                    name: ProvisionType.Rental,
                                    label: t('NAVIGATION.MENU_ITEM.ALL_PRODUCTS'),
                                    route: Navigation.RentalProductList,
                                },
                                {
                                    name: ProvisionType.Rental,
                                    label: t('NAVIGATION.MENU_ITEM.SANITARY_CONTAINERS'),
                                    route: Navigation.RentalProductListSanitary,
                                },
                                {
                                    name: ProvisionType.Rental,
                                    label: t('NAVIGATION.MENU_ITEM.STORAGE_CONTAINERS'),
                                    route: Navigation.RentalProductListStorage,
                                },
                                {
                                    name: ProvisionType.Rental,
                                    label: t('NAVIGATION.MENU_ITEM.OFFICE_CONTAINERS'),
                                    route: Navigation.RentalProductListOffice,
                                },
                            ],
                        },
                    ],
                };
            }

            navMenuList[NavArea.MY_AREA] = {
                name: t('NAVIGATION.AREAS.MY_AREA'),
                plainName: 'my-area',
                icon: 'pi pi-user',
                description: t('NAVIGATION.AREAS.MY_AREA_DESCRIPTION'),
                navArea: NavArea.MY_AREA,
                navBarItems: isUserLoggedIn.value
                    ? [
                          {
                              name: ProvisionType.Sales,
                              label: t('NAVIGATION.MENU_ITEM.OVERVIEW'),
                              route: Navigation.Account,
                          },
                          {
                              name: ProvisionType.Sales,
                              label: t('NAVIGATION.MENU_ITEM.ORDERS'),
                              route: Navigation.OrderList,
                          },
                          !isRetailer.value
                              ? undefined
                              : {
                                    name: ProvisionType.Sales,
                                    label: t('NAVIGATION.MENU_ITEM.LEADS'),
                                    route: Navigation.Leads,
                                    count: amountNewLeads.value,
                                },
                      ].filter((item) => item != null)
                    : [],
            };
            return navMenuList;
        }),
    };
}

export function useNavigationItemsAction(): NavigationItemsAction {
    const store = useNavigationItemsStore();

    return {
        setActiveArea(newValue: NavArea): void {
            store.setActiveArea(newValue);
        },
    };
}
