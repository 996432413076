<script setup lang="ts">
    import Drawer from 'primevue/drawer';
    import Button from 'primevue/button';
    import { isMobile } from '@/util/breakpoints';
    import { array, date, object, string } from 'zod';
    import FormField from '@/components/form-components/FormField.vue';
    import { toTypedSchema } from '@vee-validate/zod';
    import { useField, useForm, useIsFormValid } from 'vee-validate';
    import { useErrorHandler } from '@/composables/useErrorHandler';
    import { HttpStatusCode } from 'axios';
    import useGlobalToast from '@/composables/useGlobalToast';
    import { useMarketAction } from '@/composables/market';
    import { useOrderInfoSidebarAction, useOrderInfoSidebarQuery } from '@/composables/order-info-sidebar';
    import DatePicker from 'primevue/datepicker';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { format } from 'date-fns';
    import { computed, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import InputText from 'primevue/inputtext';
    import Message from 'primevue/message';
    import { isStringEmpty } from '@containex/common-utils';
    import { calculateRentalDays, calculateSelectedRentalDays } from '@containex/portal-business-logic';
    import { hasValidationError } from '@/util/hasValidationError';
    import { useProvisionTypeQuery } from '@/composables/provision-type';
    import { useCheckoutCartAction, useCheckoutCartQuery } from '@/checkout/composables/checkout-cart';

    const { t } = useI18n();
    const { isVisible, productToAdd } = useOrderInfoSidebarQuery();
    const orderInfoSidebarAction = useOrderInfoSidebarAction();
    const { errorToastOptions, addToast } = useGlobalToast();
    const { isRentalProvisionType } = useProvisionTypeQuery();
    const { rentalEnd, rentalStart, identifiedZipCode } = useCartQuery();
    const marketAction = useMarketAction();
    const cartAction = useCartAction();
    const checkoutCartAction = useCheckoutCartAction();
    const { checkoutCart } = useCheckoutCartQuery();

    const schema = object({
        dates: array(date()).optional(),
        zipCode: string()
            .trim()
            .min(1)
            .refine(async (code) => await marketAction.zipCodeExistsInCurrentMarket(code), {
                message: t('ERROR.CHANGE_REGION.DETAIL'),
            }),
    });

    const { handleSubmit, errors, resetForm, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const {
        value: dates,
        setValue: setDatesValue,
        resetField: resetDatesValue,
    } = useField<Date[]>(
        'dates',
        {},
        {
            initialValue:
                rentalEnd.value != null && rentalStart.value != null ? [rentalStart.value, rentalEnd.value] : undefined,
        }
    );

    const [zipCode] = defineField('zipCode');

    watch(identifiedZipCode, (newIdentifiedZipCode, oldIdentifiedZipCode) => {
        if (newIdentifiedZipCode != null) {
            zipCode.value = newIdentifiedZipCode;
        }
    });

    // handleClose() resets the form values, but they are not initialized correctly when sidebar re-opens, so we take care of it here.
    watch(isVisible, (newIsVisible) => {
        if (newIsVisible) {
            if (identifiedZipCode.value != null) {
                zipCode.value = identifiedZipCode.value;
            }
            if (rentalEnd.value != null && rentalStart.value != null) {
                setDatesValue([new Date(rentalStart.value), new Date(rentalEnd.value)]);
            } else {
                resetDatesValue({
                    value: undefined,
                });
            }
        }
    });

    const isValid = useIsFormValid();

    const hasProductToAddButNoDate = computed(() => {
        return productToAdd.value != null && dates.value == null;
    });

    const isSaveButtonDisabled = computed(() => {
        return !isValid.value || hasProductToAddButNoDate.value;
    });

    const sidebarTitle = computed(() =>
        t(
            isRentalProvisionType.value
                ? 'ORDER_INFO_SIDEBAR.RENTAL_SIDEBAR_TITLE'
                : 'ORDER_INFO_SIDEBAR.SALES_SIDEBAR_TITLE'
        )
    );

    const plzTitle = computed(() =>
        t(isRentalProvisionType.value ? 'ORDER_INFO_SIDEBAR.RENTAL_PLZ' : 'ORDER_INFO_SIDEBAR.SALES_PLZ')
    );

    const plzInfoMessage = computed(() =>
        t(
            isRentalProvisionType.value
                ? 'ORDER_INFO_SIDEBAR.RENTAL_PLZ_INFO_TEXT'
                : 'ORDER_INFO_SIDEBAR.SALES_PLZ_INFO_TEXT'
        )
    );

    const plzInputLabel = computed(() =>
        t(isRentalProvisionType.value ? 'ORDER_INFO_SIDEBAR.RENTAL_PLZ' : 'ORDER_INFO_SIDEBAR.SALES_PLZ')
    );

    const plzDescription = computed(() =>
        t(
            isRentalProvisionType.value
                ? 'ORDER_INFO_SIDEBAR.RENTAL_PLZ_DESCRIPTION'
                : 'ORDER_INFO_SIDEBAR.SALES_PLZ_DESCRIPTION'
        )
    );

    const zipCodeErrorHandler = useErrorHandler().errorHandlerFrom({
        name: 'OrderInfoSidebar',
        statusCodeHandlers: {
            [HttpStatusCode.BadRequest]() {
                addToast({
                    ...errorToastOptions,
                    summary: 'ERROR.CHANGE_REGION.SUMMARY',
                    detail: 'ERROR.CHANGE_REGION.DETAIL',
                });
            },
        },
    });

    const rentalDurationErrorHandler = useErrorHandler().errorHandlerFrom({
        name: 'OrderInfoSidebar',
        statusCodeHandlers: {
            [HttpStatusCode.BadRequest]() {
                addToast({
                    ...errorToastOptions,
                    summary: 'ERROR.CHANGE_RENTAL_DURATION.SUMMARY',
                    detail: 'ERROR.CHANGE_RENTAL_DURATION.DETAIL',
                });
            },
        },
    });

    const onSubmit = handleSubmit(async (values) => {
        let hasError = false;

        if (dates.value != null && dates.value.length === 2) {
            await rentalDurationErrorHandler.handle(async () => {
                if (dates.value[0] == null || dates.value[1] == null) {
                    hasError = true;
                    addToast({
                        ...errorToastOptions,
                        summary: 'ERROR.CHANGE_RENTAL_DURATION.SUMMARY',
                        detail: 'ERROR.CHANGE_RENTAL_DURATION.DETAIL',
                    });
                } else if (productToAdd.value != null) {
                    await cartAction.addLineItem(productToAdd.value);
                }
            });
        }

        if (hasError) {
            return;
        }

        await zipCodeErrorHandler.handle(async () => {
            const zip = values.zipCode ?? '';

            const updatedCart = await cartAction.updateRegionBasedOnZipCodeAndRentalDuration(
                zip,
                dates.value?.[0] ?? undefined,
                dates.value?.[1] ?? undefined
            );

            if (updatedCart == null) {
                hasError = true;
                addToast({
                    ...errorToastOptions,
                    summary: 'ERROR.CHANGE_CART.SUMMARY',
                    detail: 'ERROR.CHANGE_CART.DETAIL',
                });
            } else if (checkoutCart.value != null) {
                checkoutCartAction.updateCheckoutCartOrderInfo(updatedCart);
            }
        });

        if (!hasError) {
            handleClose();
        }
    });

    function handleClose(): void {
        resetForm();
        orderInfoSidebarAction.setProductToAdd(null);
        orderInfoSidebarAction.setIsVisible(false);
    }

    const rentalDuration = computed(() => {
        if (dates.value == null || dates.value.length !== 2) {
            return 0;
        }

        const [min, max] = dates.value;
        if (min == null || max == null) {
            return 0;
        }

        //We need to get to the start of the day, because if we select today the datePicker returns the current time, which can mess up the rental duration calculation.
        const minIso = new Date(format(min, 'yyyy-MM-dd'));
        return calculateRentalDays(minIso, max);
    });

    const selectedRentalDuration = computed(() => {
        if (dates.value == null || dates.value.length !== 2) {
            return 0;
        }

        const [min, max] = dates.value;
        if (min == null || max == null) {
            return 0;
        }

        //We need to get to the start of the day, because if we select today the datePicker returns the current time, which can mess up the rental duration calculation.
        const minIso = new Date(format(min, 'yyyy-MM-dd'));
        return calculateSelectedRentalDays(minIso, max);
    });
</script>

<template>
    <Drawer
        v-model:visible="isVisible"
        :position="isMobile ? 'full' : 'right'"
        :block-scroll="true"
        :style="{ width: '360px' }"
    >
        <template #container>
            <div class="header-container">
                <div class="close-button-container">
                    <h3 class="text-2xl-bold-line-height-auto">{{ sidebarTitle }}</h3>
                    <Button
                        icon="pi pi-times"
                        text
                        rounded
                        class="close-button"
                        severity="secondary"
                        @click="handleClose"
                    />
                </div>
                <div class="info-container" :class="{ 'info-container__no-padding-top': !isRentalProvisionType }">
                    <form @submit.prevent="onSubmit">
                        <div>
                            <h3 v-if="isRentalProvisionType" class="text-xl-bold-line-height-auto">{{ plzTitle }}</h3>

                            <Message
                                v-if="isStringEmpty(zipCode)"
                                icon="pi pi-info-circle"
                                severity="info"
                                :closable="false"
                                class="info-inline-message"
                                >{{ plzInfoMessage }}
                            </Message>

                            <p class="description">{{ plzDescription }}</p>

                            <FormField :label="plzInputLabel" class="form-field" :error-message="errors.zipCode">
                                <InputText
                                    v-model="zipCode"
                                    class="full-width"
                                    data-testid="order-info-sidebar-zip-code-field"
                                    :invalid="hasValidationError(errors.zipCode)"
                                />
                            </FormField>
                        </div>
                        <div v-if="isRentalProvisionType">
                            <h3 class="text-xl-bold-line-height-auto">
                                {{ t('ORDER_INFO_SIDEBAR.RENTAL_DURATION') }}
                            </h3>

                            <Message
                                v-if="hasProductToAddButNoDate"
                                icon="pi pi-info-circle"
                                severity="info"
                                :closable="false"
                                class="info-inline-message"
                                >{{ t('ORDER_INFO_SIDEBAR.RENTAL_DURATION_INFO_TEXT') }}
                            </Message>

                            <p class="description">{{ t('RENTAL.SELECT_RENTAL_PERIOD_INFO') }}</p>

                            <FormField :label="t('RENTAL.PERIOD')" class="form-field">
                                <DatePicker
                                    v-model="dates"
                                    class="full-width"
                                    show-icon
                                    show-week
                                    icon-display="input"
                                    selection-mode="range"
                                    :min-date="new Date()"
                                    :disabled-days="[0, 6]"
                                    :manual-input="false"
                                    :hide-on-range-selection="true"
                                    data-testid="order-info-sidebar-rental-duration-calendar"
                                />
                            </FormField>

                            <div v-if="dates != null && dates.length > 0" class="rental-duration">
                                <span class="pi pi-calendar rental-icon"></span>
                                <div class="rental-duration-text">
                                    <span>{{ t('RENTAL.PERIOD') }}</span>
                                    <span class="text-base-semibold-line-height-auto"
                                        >{{ rentalDuration }} {{ t('RENTAL.DAYS') }}</span
                                    >
                                </div>
                            </div>
                            <Message
                                v-if="dates != null && dates.length > 0 && selectedRentalDuration < rentalDuration"
                                icon="pi pi-info-circle"
                                severity="info"
                                :closable="false"
                                class="info-inline-message"
                                >{{ t('RENTAL.MIN_RENTAL_DURATION_MESSAGE') }}
                            </Message>
                        </div>
                        <Button
                            class="full-width submit-button"
                            :disabled="isSaveButtonDisabled"
                            type="submit"
                            data-testid="order-info-sidebar-save-button"
                            :label="t('COMMON.SAVE')"
                        />
                    </form>
                </div>
            </div>
        </template>
    </Drawer>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .info-inline-message {
        width: 100%;
    }

    .close-button-container {
        text-align: right;
        padding-bottom: main.$spacing-3;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .description {
        margin-bottom: 4px;
    }

    .close-button {
        color: main.$color-text;
    }

    .info-container {
        display: flex;
        padding: main.$spacing-6 0;

        &__no-padding-top {
            padding-top: 0;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-7;
    }

    .header-container {
        overflow: auto;
        padding: main.$spacing-4 main.$spacing-5 main.$spacing-6;
    }

    .rental-duration {
        display: flex;
        flex-direction: row;
        gap: main.$spacing-4;
        align-items: center;
        padding-top: main.$spacing-6;
    }

    .rental-duration-text {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .rental-icon {
        font-size: main.$font-size-6;
    }

    .p-message {
        margin: 1rem 0;
    }
</style>
