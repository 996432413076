import { useSalesCartStore } from '@/stores/sales-cart';
import type { MedusaError } from '@medusajs/utils/dist/common/errors';
import { computed, type ComputedRef } from 'vue';
import { useCartSidebarAction } from './cart-sidebar';
import { useMarketQuery } from '@/composables/market';
// eslint-disable-next-line import-x/no-cycle
import { useAuthenticationAction } from '@/composables/authentication';
import { isMedusaErrorResponse } from '@/model/medusa-error-response';
import { useToastHelpers } from '@/composables/showToastHelpers';
import { CartNotInitializedError } from '@/model/cart-not-initialized-error';
import {
    cartApi,
    type Cart,
    type LineItem,
    type CtxProduct,
    type CtxProductVariant,
} from '@containex/portal-backend-api-client';
import { format, parseISO } from 'date-fns';
import { filterProductVariantForProvisionType } from '@/util/filterProductVariantForProvisionType';
import { useCheckoutDeliveryOptionAction } from '@/checkout/composables/checkout-delivery-option';
import { useMarketStore } from '@/stores/market';
import { globalLogger } from '@cloudflight/logger';
import { httpClient } from '@/common/api/http-client';
import { medusaClient } from '@/common/api/medusa-client';
import { DepotType, PaymentMethod, ProvisionType } from '@containex/portal-backend-dto';
import { useRentalCartStore } from '@/stores/rental-cart';
import { useProvisionTypeQuery } from './provision-type';
import { ProvisionTypeNotGivenError } from '@/model/provision-type-not-given';
import { DEPOT_TYPE_PRODUCTION_SITE_QUANTITY_LIMIT } from '@containex/portal-business-logic';
import { getReferrer } from '@/util/referrer';

interface CartLineItemQuantityLookupRecordItem {
    quantity: number;
    quantityLimit: number | undefined;
    inventoryQuantity: number;
}

type CartLineItemQuantityLookupRecord = Record<string, CartLineItemQuantityLookupRecordItem>;

export interface CartQuery {
    cart: ComputedRef<Cart | null>;
    lineItemsQuantityCount: ComputedRef<number>;
    rentalStart: ComputedRef<Date | undefined>;
    rentalEnd: ComputedRef<Date | undefined>;
    currentRegionId: ComputedRef<string | undefined>;
    currentZipCode: ComputedRef<string | undefined>;
    hasRegionSelected: ComputedRef<boolean>;
    identifiedZipCode: ComputedRef<string | undefined>;
    lineItemsQuantityLookupRecord: ComputedRef<CartLineItemQuantityLookupRecord | undefined>;
}

export interface CartAction {
    addLineItem(product: CtxProduct): Promise<Cart | MedusaError>;

    addLineItemByVariantId(
        product: CtxProduct,
        variantId: string,
        ignoreCartSidebar?: boolean
    ): Promise<Cart | MedusaError>;

    initCartStore(): Promise<void>;

    resetCartStore(): void;

    resetAllCartStores(): void;

    updateLineItemQuantity(lineItem: LineItem, newQuantity: number): Promise<Cart | MedusaError>;

    ensureCartExists(): Promise<void>;

    createCart(): Promise<Cart>;

    retrieveCart(cartId?: string): Promise<Cart | null>;

    updateSessionPostalCode(sessionPostalCode: string): Promise<Cart | null>;

    isVariantAddableToCart(ctxVariant: CtxProductVariant | undefined): boolean;

    updateRegionBasedOnZipCodeAndRentalDuration(
        sessionPostalCode: string,
        rentalStart?: Date,
        rentalEnd?: Date
    ): Promise<Cart | null>;

    retrievePureDatabaseCart(cartId: string): Promise<Cart | null>;

    updateCartBeforeCompleting(
        checkoutTosAccepted: boolean,
        paymentMethod: PaymentMethod,
        orderNote: string | undefined,
        orderReference: string | undefined
    ): Promise<Cart | null>;
}

export function useCartQuery(): CartQuery {
    const salesCartStore = useSalesCartStore();
    const rentalCartStore = useRentalCartStore();
    const { currentProvisionType } = useProvisionTypeQuery();

    const store = computed(() => {
        switch (currentProvisionType.value) {
            case ProvisionType.Rental:
                return rentalCartStore;
            case ProvisionType.Sales:
                return salesCartStore;
            case undefined:
            default:
                throw new ProvisionTypeNotGivenError();
        }
    });
    const marketStore = useMarketStore();

    return {
        cart: computed(() => store.value.cart),
        lineItemsQuantityCount: computed(() => {
            if (store.value.cart != null) {
                return store.value.cart.items.reduce((sum, current) => {
                    if (current.parent_line_item_id == null) {
                        return sum + current.quantity;
                    }
                    return sum;
                }, 0);
            }

            return 0;
        }),
        lineItemsQuantityLookupRecord: computed(() => {
            return store.value.cart?.items.reduce((acc, currentItem) => {
                if (currentItem.variant_id != null) {
                    acc[currentItem.variant_id] = {
                        quantity: currentItem.quantity,
                        quantityLimit:
                            currentItem.variant.depotQuantity.depot.type === DepotType.ProductionSite
                                ? DEPOT_TYPE_PRODUCTION_SITE_QUANTITY_LIMIT
                                : undefined,
                        inventoryQuantity: currentItem.variant.inventory_quantity ?? 0,
                    };
                }
                return acc;
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            }, {} as CartLineItemQuantityLookupRecord);
        }),
        rentalStart: computed(() =>
            store.value.cart?.rentalStart != null ? parseISO(store.value.cart.rentalStart) : undefined
        ),
        rentalEnd: computed(() =>
            store.value.cart?.rentalEnd != null ? parseISO(store.value.cart.rentalEnd) : undefined
        ),
        currentRegionId: computed(() => store.value.cart?.region_id),
        currentZipCode: computed(() => store.value.cart?.sessionPostalCode ?? undefined),
        hasRegionSelected: computed(
            () => store.value.cart?.region_id != null && store.value.cart?.sessionPostalCode != null
        ),
        identifiedZipCode: computed(() => store.value.cart?.sessionPostalCode ?? marketStore.identifiedZipCode),
    };
}

export function useCartAction(): CartAction {
    const salesCartStore = useSalesCartStore();
    const rentalCartStore = useRentalCartStore();
    const { isRentalProvisionType, currentProvisionType } = useProvisionTypeQuery();

    const store = computed(() => {
        switch (currentProvisionType.value) {
            case ProvisionType.Rental:
                return rentalCartStore;
            case ProvisionType.Sales:
                return salesCartStore;
            case undefined:
            default:
                throw new ProvisionTypeNotGivenError();
        }
    });

    const cartSidebarAction = useCartSidebarAction();
    const checkoutDeliveryOptionAction = useCheckoutDeliveryOptionAction();
    const { market } = useMarketQuery();
    const toastHelpers = useToastHelpers();

    const currentSalesChannelId = computed(() => {
        if (currentProvisionType.value === ProvisionType.Rental) {
            return market.value?.rentalChannelId;
        } else if (currentProvisionType.value === ProvisionType.Sales) {
            return market.value?.salesChannelId;
        }

        return undefined;
    });

    return {
        async initCartStore(): Promise<void> {
            const cart = await retrieveCart(store.value.cartId);

            if (cart == null) {
                store.value.resetCartStore();
                return;
            }

            store.value.setCart(cart);

            checkoutDeliveryOptionAction.updateForLineItemGroups(cart.lineItemGroups ?? []);
        },
        async addLineItem(product: CtxProduct): Promise<Cart | MedusaError> {
            const filteredVariants = product.variants
                .filter((variant) =>
                    filterProductVariantForProvisionType(variant, market.value?.code, isRentalProvisionType.value)
                )
                .sort((a, b) => {
                    if ((b.calculated_price ?? 0) > 0) {
                        return 1;
                    } else if ((a.calculated_price ?? 0) > 0) {
                        return -1;
                    }

                    return 0;
                });

            const variantId = filteredVariants[0]?.id;

            if (variantId == null) {
                throw new Error(`variant id is null but should never be for product  ${String(product.id)}`);
            }

            return await this.addLineItemByVariantId(product, variantId);
        },
        async addLineItemByVariantId(
            product: CtxProduct,
            variantId: string,
            ignoreCartSidebar = false
        ): Promise<Cart | MedusaError> {
            await this.ensureCartExists();

            if (store.value.cart == null) {
                throw new CartNotInitializedError();
            }

            try {
                const { cart } = await cartApi.createLineItem(medusaClient, store.value.cart.id, {
                    variant_id: variantId,
                    quantity: 1,
                });

                // this needs to be called after modifying the line item quantity in the cart
                // as we need to have the discount informations based on the new quantities, etc.
                // and the discounts are not included in the response of the original medusa endpoints
                await this.ensureCartExists();

                if (!ignoreCartSidebar) {
                    cartSidebarAction.setIsVisible(true);
                    // @ts-expect-error at this point we can assume title exists
                    cartSidebarAction.setLastLineItemName(product.title);
                }

                return cart;
            } catch (error: unknown) {
                if (isMedusaErrorResponse(error)) {
                    toastHelpers.showToastForAddItemResponse(error.response.data, product.title ?? '');

                    return error.response.data;
                }

                throw error;
            }
        },
        resetCartStore(): void {
            store.value.resetCartStore();
        },
        resetAllCartStores(): void {
            salesCartStore.resetCartStore();
            rentalCartStore.resetCartStore();
        },
        async updateLineItemQuantity(lineItem: LineItem, newQuantity: number): Promise<Cart | MedusaError> {
            await this.ensureCartExists();
            const cartLineReq = { quantity: newQuantity };

            if (store.value.cart == null) {
                throw new CartNotInitializedError();
            }

            try {
                const { cart } = await cartApi.updateLineItem(
                    medusaClient,
                    store.value.cart.id,
                    lineItem.id,
                    cartLineReq
                );

                // this needs to be called after modifying the line item quantity in the cart
                // as we need to have the discount informations based on the new quantities, etc.
                // and the discounts are not included in the response of the original medusa endpoints
                await this.ensureCartExists();

                return cart;
            } catch (error: unknown) {
                if (isMedusaErrorResponse(error)) {
                    toastHelpers.showErrorToastForChangeQuantityResponse(error.response.data, lineItem, newQuantity);

                    return error.response.data;
                }

                throw error;
            }
        },
        async ensureCartExists(): Promise<void> {
            if (currentProvisionType.value == null) {
                return;
            }
            const existingCart = await retrieveCart(store.value.cartId);
            const cart = existingCart ?? (await this.createCart());

            store.value.setCart(cart);
        },
        async createCart(): Promise<Cart> {
            const salesChannelId = currentSalesChannelId.value;
            let regionId: string | undefined;
            let isRentalCart = false;

            switch (currentProvisionType.value) {
                case ProvisionType.Rental:
                    regionId = market.value?.rentalRegionIds[0] ?? undefined;
                    isRentalCart = true;
                    break;
                case ProvisionType.Sales:
                    regionId = market.value?.salesRegionIds[0] ?? undefined;
                    break;
                case undefined:
                default:
                    regionId = undefined;
                    break;
            }

            if (salesChannelId == null) {
                throw Error('Missing sales channel id - cannot create a new cart');
            }

            if (regionId == null) {
                throw Error('Missing region id - cannot create a new cart');
            }

            try {
                const { data } = await cartApi.customCreateCart(httpClient, {
                    salesChannelId,
                    regionId,
                    isRentalCart,
                    referrer: getReferrer(),
                });

                return data;
            } catch (error) {
                if (isMedusaErrorResponse(error)) {
                    toastHelpers.showToastForCartCreationError();
                }
                globalLogger.error('createCart', 'unable to create a new cart');
                throw error;
            }
        },
        async retrieveCart(cartId?: string): Promise<Cart | null> {
            return await retrieveCart(cartId ?? store.value.cartId);
        },
        async retrievePureDatabaseCart(cartId: string): Promise<Cart | null> {
            const response = await cartApi.retrieveCart(medusaClient, cartId);
            return response.cart;
        },
        async updateSessionPostalCode(sessionPostalCode: string): Promise<Cart | null> {
            await this.ensureCartExists();

            if (store.value.cartId == null) {
                throw new CartNotInitializedError();
            }

            if (currentSalesChannelId.value != null && market.value != null) {
                const { data: updatedCart } = await cartApi.customUpdateCart(httpClient, {
                    cart: {
                        salesChannelId: currentSalesChannelId.value,
                        loggedOutCartId: store.value.cartId,
                    },
                    update: {
                        sessionPostalCode,
                        resolveRegionBasedOnSessionPostalCode: false,
                        deleteShippingAddress: false,
                        resolveRegionOptions: undefined,
                        rentalDuration: undefined,
                        orderNote: undefined,
                        orderReference: undefined,
                        referrer: getReferrer(),
                    },
                });

                store.value.setCart(updatedCart);
                return updatedCart;
            }

            return null;
        },
        async updateCartBeforeCompleting(
            checkoutTosAccepted: boolean,
            paymentMethod: PaymentMethod,
            orderNote: string | undefined,
            orderReference: string | undefined
        ): Promise<Cart | null> {
            await this.ensureCartExists();

            if (store.value.cartId == null) {
                throw new CartNotInitializedError();
            }

            if (currentSalesChannelId.value != null && market.value != null) {
                const { data: updatedCart } = await cartApi.customUpdateCart(httpClient, {
                    cart: {
                        salesChannelId: currentSalesChannelId.value,
                        loggedOutCartId: store.value.cartId,
                    },
                    update: {
                        deleteShippingAddress: false,
                        resolveRegionBasedOnSessionPostalCode: false,
                        checkoutTosAccepted,
                        paymentMethod,
                        orderNote,
                        orderReference,
                        referrer: getReferrer(),
                    },
                });

                store.value.setCart(updatedCart);
                return updatedCart;
            }

            return null;
        },
        async updateRegionBasedOnZipCodeAndRentalDuration(
            sessionPostalCode: string,
            rentalStart?: Date,
            rentalEnd?: Date
        ): Promise<Cart | null> {
            await this.ensureCartExists();
            if (store.value.cartId == null) {
                throw new CartNotInitializedError();
            }

            if (currentSalesChannelId.value != null && market.value != null && currentProvisionType.value != null) {
                const rentalDuration =
                    rentalStart != null && rentalEnd != null
                        ? { start: format(rentalStart, 'yyyy-MM-dd'), end: format(rentalEnd, 'yyyy-MM-dd') }
                        : undefined;

                const { data: updatedCart } = await cartApi.customUpdateCart(httpClient, {
                    cart: {
                        salesChannelId: currentSalesChannelId.value,
                        loggedOutCartId: store.value.cartId,
                    },
                    update: {
                        sessionPostalCode,
                        resolveRegionBasedOnSessionPostalCode: true,
                        deleteShippingAddress: true,
                        resolveRegionOptions: {
                            marketCode: market.value.code,
                            provisionType: currentProvisionType.value,
                        },
                        rentalDuration,
                        referrer: getReferrer(),
                    },
                });

                store.value.setCart(updatedCart);
                return updatedCart;
            }

            return null;
        },
        isVariantAddableToCart(ctxVariant: CtxProductVariant | undefined): boolean {
            const cart = store.value.cart;
            if (cart == null) {
                return true;
            }

            if (ctxVariant?.id != null && ctxVariant.inventory_quantity != null) {
                const variantLineItem = cart.items.find((item) => item.variant_id === ctxVariant?.id) ?? null;
                if (variantLineItem == null) {
                    return ctxVariant.inventory_quantity > 0;
                }
                const maxQuantity =
                    ctxVariant?.depotQuantity.depot.type === DepotType.ProductionSite
                        ? DEPOT_TYPE_PRODUCTION_SITE_QUANTITY_LIMIT
                        : ctxVariant.inventory_quantity;
                return variantLineItem.quantity < maxQuantity;
            }
            globalLogger.error('isVariantAddableToCart', 'variant is not set');
            return false;
        },
    };
}

async function retrieveCart(cartId: string | null): Promise<Cart | null> {
    const { currentSalesChannelId } = useMarketQuery();
    const authenticationAction = useAuthenticationAction();

    await authenticationAction.fetchCurrentCustomerIfMissing.perform();

    if (currentSalesChannelId.value != null) {
        try {
            const fetchedCartRes = await cartApi.getCartByCustomerId(httpClient, {
                salesChannelId: currentSalesChannelId.value,
                loggedOutCartId: cartId ?? undefined,
            });

            return fetchedCartRes.data ?? null;
        } catch {
            return null;
        }
    }

    // no cart fetched
    return null;
}
